import { Link } from "@remix-run/react";
import type { ReactNode } from "react";

export function Layout(props: { children: ReactNode }) {
	return (
		<>
			<div className="relative mx-auto flex max-w-[1400px] flex-col px-6">
				{props.children}
			</div>
			<footer className="mt-32 mb-6 text-center">
				<Link to="/sitemap">Sitemap</Link>
			</footer>
		</>
	);
}
